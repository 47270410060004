/*=========================================================================================
  File Name: moduleDiscount.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleDiscountState.js'
import mutations from './moduleDiscountMutations.js'
import actions from './moduleDiscountActions.js'
import getters from './moduleDiscountGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

